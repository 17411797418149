<template>
  <div class="titaniumproducts">
    <HeadMobile/>
    <div class="titaniumproducts-main">
      <div class="titaniumproducts-main-weizhi">{{$store.state.staticName.position}}：
        <span @click="goHome">{{$store.state.staticName.home}}</span>
        >
        <span @click="goDinzhi">{{$store.state.staticName.customized}}</span>
        >
        {{$store.state.staticName.Customizationoia}}
      </div>
      <div class="titaniumproducts-main-titles">
        <div class="titaniumproducts-main-title">
          {{$store.state.staticName.Customizationoctp}}
        </div>
        <div class="titaniumproducts-main-subtitle">
          {{$store.state.staticName.cus_subhead}}
        </div>
      </div>
      <div class="titaniumproducts-main-customizedcase">
<!--        <h1 class="titaniumproducts-main-customizedcase-tatle">{{CustomizationByClassify.detail}}</h1>-->
        <div class="titaniumproducts-main-customizedcase-box">
          <div class="box-item" v-for="(item,i) in CustomizationByClassify.detailImg" :key="'item'+i">
            <el-image
                class="box-item"
                :src="item"
                :preview-src-list="[item]"
            ></el-image>
          </div>
<!--          <div class="box-item"></div>-->
<!--          <div class="box-item"></div>-->
<!--          <div class="box-item"></div>-->
        </div>
      </div>
      <div class="title">{{$store.state.staticName.Customizedspofctp}}</div>
      <div class="technological-process">
        <div class="technological-process-item" v-for="(item,i) in CustomizationByClassify.flow" :key="'qqq'+i">
          <div class="left" v-if="i!==0">
<!--            iconzu121-->
            <span class="iconfont iconzu121 item-img"></span>
<!--            <svg t="1609221603937" class="icon" viewBox="0 0 1795 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="1214" width="200" height="200"><path d="M1795.506849 512L967.890411 1024V0z" fill="#83CFFE" p-id="1215"></path><path d="M0 322.630137h981.917808v406.794521H0z" fill="#83CFFE" p-id="1216"></path></svg>-->
          </div>
<!--          title="标题"-->
          <el-popover
              placement="top-start"

              width="200"
              trigger="click"
              :content="item.text">
            <div class="right" slot="reference">
              <img class="item-img" :src="item.abbreviation[0]" />
              <div class="item-title">{{item.title}}</div>
              <!--            <div class="item-introduce">{{item.text}}</div>-->
            </div>
          </el-popover>

        </div>
      </div>
      <div class="corecustomize-box-info" v-for="(item,i) in myIt" :key="'i'+i">
        <h1 class="fu-h1">{{item.title}}</h1>
        <div class="xian"></div>
        <h1 class="fu-h2">{{item.subhead}}</h1>
        <p>
          {{item.content}}
        </p>
        <div class="img-box" >
          <div class="img-item" v-for="item1 in item.abbreviation">
              <img class="img-item" :src="item1">
          </div>
        </div>
      </div>

    </div>
    <FootMobile/>
  </div>
</template>

<script>
import HeadMobile from "@/components/mobile/HeadMobile";
import FootMobile from "@/components/mobile/FootMobile";
import { mapState } from 'vuex';
export default {
  name: "TitaniumProducts",
  components: {
    HeadMobile,
    FootMobile
  },
  data(){
    return{
      id:'',
      CustomizationByClassify:[],
      myIt:[],
    }
  },
  watch:{
    "$route.params.id": {
      handler(newVal) {
        this.reFresh()
        // this.fenleId = newVal
      }
    },
    "$store.state.languageType": {
      handler(newVal) {
        this.getData();
        this.getMyIt()
      },
      // deep: true // 深度监听
    }
  },
  computed: {
    ...mapState({
      languageType: (state) => state.languageType, // 语言类型
    })
  },
  created() {
    this.reFresh();
  },
  methods:{
    reFresh(){
      this.id=this.$route.params.id;
      this.getData();
      this.getMyIt()
    },
    goHome(){
      this.$router.push({path:"/HomeMobile"})
    },
    goDinzhi(){
      this.$router.push({path:"/CorecustomizaMobile"})
    },
    getMyIt(){
      this.$axios({
        url:'/queryCPY_InfoByCus',
        method:'get',
        params:{
          id:this.id,
          type: this.languageType
        }
      }).then(res=>{
        this.myIt = res.data.value;
      }).catch(err=>{
        console.log(err)
      })
    },
    getData(){
      this.$axios({
        url:'/queryCUS_CustomizedDetail',
        method:'get',
        params:{
          id:this.id,
          type: this.languageType
        }
      }).then(res=>{
        this.CustomizationByClassify = res.data.value;
      }).catch(err=>{
        console.log(err)
      })
    }

  }
}
</script>

<style lang="scss" scoped>
.titaniumproducts{
  background-color: #fff;
  .titaniumproducts-main{
    width: 365px;
    margin: 0 auto 86px;
    .titaniumproducts-main-weizhi{

      font-size: 10px;
      font-family: PingFang SC, PingFang SC-Regular;
      font-weight: 400;
      text-align: left;
      color: #333333;
      span:hover{
        color: #1850AF;
        cursor: pointer;
      }
    }
    .titaniumproducts-main-titles{
      margin-top: 11px;
      margin-bottom: 14px;
      text-align: center;
      .titaniumproducts-main-title{
        font-size: 14px;
        font-weight: 400;
        text-align: center;
        color: #333333;
      }
      .titaniumproducts-main-subtitle{
        opacity: 0.4;
        font-size: 12px;
        font-weight: 400;
        text-align: center;
        color: #666666;
      }
    }
    .titaniumproducts-main-customizedcase{
      .titaniumproducts-main-customizedcase-tatle{
        margin-top: 30px;
        margin-bottom: 22px;
        font-size: 30px;
        font-weight: 500;
        text-align: left;
        color: #4d4d4d;
      }
      .titaniumproducts-main-customizedcase-box{
        display: flex;
        flex-wrap: wrap;
        justify-content: start;
        .box-item:nth-child(4n-3){
          margin-left: 0px;
        }
        .box-item{
          margin-left: 5px;
          width: 115px;
          height: 78px;
          background: #d1d1d1;
        }
      }
    }
    .title{
      margin-top: 28px;
      margin-bottom: 11px;
      font-size: 14px;
      font-weight: 400;
      text-align: center;
      color: #333333;
    }
    .technological-process{
      display: flex;
      justify-content: start;
      flex-wrap: wrap;
      .technological-process-item{
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 16px;
        .left{
          width: 42px;
          display: flex;
          justify-content: center;
          span{
            font-size:14px;
            color:#83cffe;
            //width: 128px;
            //height: 73px;
            //background-color: #777777;
          }
        }
        .right{
          width: 57px;
          //height: 300px;
          //background-color: #999;
          display: flex;
          flex-direction: column;
          justify-content: center;
          //align-items: center;
          .item-img{

            width: 57px;
            height: 57px;
            border-radius: 99999px;
            background-color: #d1d1d1;
          }
          .item-title{
            margin-top: 2px;
            text-align: center;
            margin-bottom: 5px;
            font-size: 14px;
            font-weight: 500;
            color: #1850af;
          }
          .item-introduce{
            max-width: 57px;
            word-wrap: break-word;
            text-align: left;
            font-size: 10px;
            font-weight: 500;
            color: #4d4d4d;
          }
        }

      }
    }
    .corecustomize-box-info{
      width: 366px;
      margin: 12px auto 0;

      .fu-h1{
        font-size: 14px;
        font-weight: 500;
        text-align: left;
        color: #333333;
      }
      .fu-h2{
        font-size: 12px;
        font-weight: 500;
      }
      .xian{
        margin-top: 1px;
        //margin-bottom: 20px;
        width: 366px;
        height: 1px;
        background-color: #666666;
        //border: 1px solid #666666;
      }
      p{
        margin-top: 6px;
        text-indent:2em;
        font-size: 12px;
        font-weight: 400;
        text-align: left;
        color: #666666;
        margin-bottom: 17px;
      }
      .img-box{
        width: 100%;
        display: flex;
        justify-content: start;
        flex-wrap: wrap;
        .img-item:nth-child(3n-2){
          margin-left: 0px;
        }
        .img-item{
          margin-left: 12px;
          width: 111px;
          height: 84px;
          margin-bottom: 8px;
          background: #d1d1d1;

          img{
            width: 111px;
            height: 84px;

          }
        }
      }
    }
  }
}
</style>